.container {
    overflow-anchor: none;

    height: 30vw;
    width: 30vw;
    max-height: 425px;
    max-width: 500px;
    margin: 0 10px;
    border-radius: 7px;
    box-shadow: 2px 2px 5px rgba(0,0,0, .5);
    overflow: hidden;
    .bgImage{
        width: 100%;
        height: 100%;
        transition-duration: 0.5s;
        position: relative;
        background-size: cover;
        background-position: top;
    }
    .toggle{
        display: none;
    }
    .textBox {
        background-color: rgba(255,255,255, 0.55);
        position: absolute;
        bottom: 0;
        width: 100%;
        @media screen and (max-width: 990px) {
            &:hover {
                cursor: pointer;
            }
        }
        .title{
            display: flex;
            justify-content: center;
            align-items: center;
            .toggleIcon{
                display: none;
                margin: -3px 0 0 5px ;
                // filter: invert(48%) sepia(77%) saturate(4976%) hue-rotate(355deg) brightness(101%) contrast(98%);
                @media screen and (max-width: 990px) {
                    display: inline;
                }
            }
        }
        h1 {
            margin: 10px 0;
            font-weight: 400;
            font-size: 1.2em;
            text-transform: uppercase;
            @media screen and (max-width: 990px) {
                font-size: 3.5vw;
            }
            @media screen and (max-width: 500px) {
                font-size: 5vw;
            }
            
        }
        p { 
            margin: 0 20px 15px 20px;
            text-align: center;
            line-height: 1.25em;
            font-size: 1em;
            @media screen and (max-width: 990px) {
                font-size: 1.5em;
            }
            @media screen and (max-width: 500px) {
                font-size: 1em;
            }
            .hide {
                display: none;
            }
        }
    }

}

.hover {
        transform: scale(1.05);
        // .textBox{
        //     transform: scale(0.95);
        // }
        @media screen and (max-width: 990px) {
            transform: none;
        }
}

@media screen and (max-width: 990px) {
    .hover{
        transform: none;
    }
    .container {
        background-position: top;
        width: 100%;
        max-width: none;
        height: 80vw;
        max-height: 70vw;
        margin: 0;
        border-radius: 0;   
    }
}