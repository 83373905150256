.container {
	text-align: center;
	background-size: cover;
	background-position: top;
	position: relative;
	max-height: 400px;
    padding-bottom: 35px;

	.title {
		padding-top: 45px;
		padding-bottom: 0;
		margin-bottom: 0;
		color: white;
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		font-weight: 200;
        @media screen and (min-width: 1400px) {
            font-size: 3em;
        }
	}

	.overlay {
		background-color: rgba($color: #000000, $alpha: 0.7);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.overoverlay {
		background: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0.75) 0%,
			rgba(0, 0, 0, 0) 25%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.75) 100%
		);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		pointer-events: none;
	}

	.carouselContainer {
		margin: 0 auto;
        .dots {
            opacity: 0.5;
        }
		.carouselItem {
			
			color: white;
			margin: 125px auto 90px;
			padding: 0 25px;
			@media screen and (min-width: 1400px) {
				padding: 0 120px;
                margin: 140px auto 120px;
			}
            @media screen and (max-width: 990px) {
				padding: 0 12vw;
			}

			h1 {
				font-weight: 300;

			}
			p {
				position: relative;
				font-size: 1.3em;
				font-weight: 500;
				line-height: 1.3em;
                @media screen and (max-width: 990px) {
                    font-size: 1.2em;
                    font-weight: 400;
                }
				.parenthesis {
					// position: absolute;
					top: -15px;
					left: -30px;
					padding: 0;
					height: 1.3em;
					transform: scale(1.2) translate(-5px, -9px);

				}
			}
			h6 {
				font-weight: 300;
				text-transform: uppercase;
                position: absolute;
                left: 50%;
                bottom: -40px;
				transform: translate(-50%, 0%);
			}
		}
	}
}
