.container {
    display: flex;
    position: relative;
    .iconsContainer {
        margin: 60px auto 6em;
        width: 100%;
        max-width: 1300px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .bottomHex {
        position: absolute;
        bottom: 5%;
        left: 2%;
        transform: scale(2);
    }
}