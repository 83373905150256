.modalContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    .content{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 15px;
        padding: 25px;
        max-width: 80vw;
        max-height: 80vh;
        overflow: scroll;
        .close {
            position: fixed;
            right: 10px;
            top: 10px;
        }
    }
}

.darkBG {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: .5);

}