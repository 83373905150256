.header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    h2 {
        font-size: 2.25em;
        @media screen and (max-width: 600px) {
            font-size: 1.15em;
        }
    }
    padding: 15px 35px;
    img {
        @media screen and (max-width: 600px) {
            max-width: 75%;
        }
    }
}

.whiteGlove {
    width: 100%;
    @media screen and (max-width: 600px) {
        width: 60vw;
    }
}

.satisfactionItems{
    display: block;
    text-align: left;
    padding-top: 30px;
    li {
	    padding-bottom: 10px;
    }
    h6 {
        font-size: 16px !important;
        line-height: 1.5em !important;
        color: #666;
        font-weight: 400;
        
    }
    .satifactionItems li > h6 {
        font-size: 16px !important;
        line-height: 1.5em !important;
        color: #666;
    }
}

.price {
    font-size: 2em;
    color: red;
}


.warrantyTable {
    border: solid black;
    max-width: 200px;
    tr {
        border: solid black;
    }
    th {
        border: solid black;
        padding: 0.15em;
        max-width: 200px;
    }
    td {
        border: solid black;
        padding: 0.15em;
        max-width: 200px;
    }
    @media screen and (max-width: 500px) {
        font-size: 0.5em;
    }
}

