.iconContainer {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    text-align: center;
    @media screen and (max-width: 990px) {
        margin: 25px 12vw;
    }
    @media screen and (max-width: 575px) {
        margin: 25px 10vw;
    }
    @media screen and (max-width: 400px) {
        margin: 25px 5vw;
    }

    .image {
        margin: 15px auto;
        width: 90px;
        height: 90px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (max-width: 575px) {
            width: 90px;
        }
    }
    .text {
        font-size: 1em;
        text-transform: uppercase;
        max-width: 120px;
    }
    .link{
        color: black;
        &:hover{
            cursor: pointer;
        }
    }

}