.wrapper {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 990px) {
        display: inline;
    }
}
.container {
    max-width: 1300px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 990px){
        flex-direction: column;
    }
}