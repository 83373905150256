.wrapper {
    padding: 5px 0 25px;
    .top {
        display: flex;

        align-items: center;
        min-height: 320px;
        .rightSection {
            width: 65%;
            text-align: center;
            @media screen and (max-width: 1200px){
                text-align: end;
                // width: 45%;
            }
        }
        .productImg {
            max-width: 100%;
            max-height: 300px;
        }
        .scaled {
            transform: scale(1.05);
        }
        .leftSection{
            width: 30%;
            margin: 5% 0 5% 7%;
            @media screen and (max-width: 1200px){
                width: 45%
            }
            .affirmLogo{
                height: 1.1em;
                transform: translate(-5px, -4.1px);
            }
            .logo {
                max-width: 130%;
                height: 42px;
                margin-bottom: 5px;
                @media screen and (max-width: 1200px){
                    max-width: 125%;
                }
            }
            h1 {
                margin: 0;
                font-size: 1.75em;
                max-width: 150%;
                @media screen and (max-width: 1200px){
                    font-size:1.5em;
                }
            }
            p{
                margin: 0;
                font-size: 0.9em;
            }
            .tagLineLogo {
                .logo {
                    height: 16px;
                    margin: 0;
                }
                .tagLine {
                    margin: 0 0 0;
                    font-size: 18px;
                }
            }
        }
    }
    
    .bottom {
        text-align: center;
        .points {
            margin: 0 0 20px;
            span {
                line-height: 0;
                font-weight: 900;
                // font-size: 1.25em;
                // transform: translate();
            }
            p {
                padding: 0;
                margin: 0 0 0 -5px;
                font-size: 0.85em;
                letter-spacing: 0.25px;
            }
        }
        .btn {
            width: 150px;
            border-radius: 4px;
            border: none;
            text-transform: uppercase;
            background: #D3222A;
            color: white;
            padding: 8px 0px;
            box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.4);
        }
    }


}

